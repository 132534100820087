import React from 'react'
import ForgotPasswordEmailInput from '../components/forgotpass/ForgotPasswordEmailInput'
import Navbar from "../components/common/Navbar";

const ForgotPassword = () => {
  return (
    <div className='Container-ForgotPassword'>
        <Navbar/>
        <ForgotPasswordEmailInput/>
    </div>
  )
}

export default ForgotPassword