import React, { useState } from "react";
import "./Forgot.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertSuccess,
} from "../common/utils";

function ChangePassword(emailValue) {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}validate_user_change_forgot_pass`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password1 === password2) {
      const data = {
        email: emailValue.emailValue,
        newPass: password1,
      };
      const response = await axios.post(route, data, {
        "Content-Type": "application/json",
        withCredentials: true,
      });

      if (response.status == 200) {
        swalAlertSuccess("CONTRASEÑA MODIFICADA EXITOSAMENTE", "", () => {
          navigate("/");
        });
      } else {
        if (response.status == 400) {
          swalAlertInfo(
            "DATOS INVÁLIDOS",
            "Revise la contraseña ingresada",
            () => {}
          );
        } else {
          swalAlertDanger("ERROR EN EL SERVIDOR", "", () => {});
        }
      }
    } else {
      alert("Las contraseñas no coinciden.");
    }
  };

  return (
    <div className="container container-password noselect">
      <div className="row">

      <div className="col-6 mt-4">      <h1>Cambiar Contraseña</h1>
      </div>
      <div className="col-6">
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Nueva Contraseña"
          value={password1}
          onChange={(e) => setPassword1(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirmar Nueva Contraseña"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />
        <button type="submit" className="submit-button-forgot">
          Cambiar Contraseña
        </button>
      </form>
      </div>
      </div>
    </div>
  );
}

export default ChangePassword;
