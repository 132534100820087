import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../common/utils";
import { useNavigate } from "react-router-dom";

const DeleteEvent = () => {
  const [eventsList, setEventsList] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}getEventsList`;

  useEffect(() => {
    axios
      //.get("/getEventsList")
      .get(route, {
        withCredentials: true,
      })

      .then((response) => {
        setEventsList(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener el listado de eventos:", error);
      });
  }, []);

  const handleDeleteEvent = (id, name) => {
    swalConfirmationAlert(
      "¿DESEA ELIMINAR ESTE EVENTO?",
      "No podrá deshacer esta acción",
      async () => {
        const route = `${apiUrl}delete_event`;
        const response = await axios.post(
          route,
          { id, name },
          {
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          swalAlertSuccess("EVENTO ELIMINADO", "", () => {
            navigate("/administrator_naiken");
          });
        } else {
          swalAlertDanger("ERROR AL ELIMINAR EL EVENTO", "", () => {
            navigate("/administrator_naiken");
          });
        }
      }
    );
  };

  return (
    <div className="user-list-container">
      <h2>Listado de Eventos</h2>
      <div className="table-container">
        <table className="user-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Fecha</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {eventsList.map((event) => (
              <tr key={event.id_event}>
                <td>{event.id_event}</td>
                <td>{event.name_event}</td>
                <td>{event.date_event}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      handleDeleteEvent(event.id_event, event.name_event)
                    }
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DeleteEvent;
