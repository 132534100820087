export const MenuItems = [
  {
    title: "INICIO",
    url: "/user",
    cName: "nav-links",
  },
  {
    title: "EVENTOS",
    url: "/eventos-user",
    cName: "nav-links",
  },
  {
    title: "PERFIL",
    url: "/perfil",
    cName: "nav-links",
  },
];
