import { swalAlertDanger } from "../components/common/utils";

export const verifyRoutes = async (user) => {
  switch (user.user_rol) {
    case 0:
      return "/administrator_naiken";
    case 1:
      return "/user";
    default:
      break;
  }
};

export const validateRole = (user_rol, allowedRoles) => {
  const invalidRol = !allowedRoles.includes(user_rol);

  if (invalidRol) {
    swalAlertDanger(
      "ERROR!!! Usted no cuenta con las credenciales para ingresar a este sitio",
      "Por favor vuelva a ingresar a la sección correspondiente",
      () => {
        window.location.href = "/";
      }
    );
    return false;
  }

  return true;
};
