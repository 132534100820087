import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  swalAlertDanger,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../common/utils";
import { useNavigate } from "react-router-dom";

const FormAddPhotos = () => {
  const [eventsList, setEventsList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar el spinner
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}getEventsList`;

  useEffect(() => {
    axios
      .get(route, {
        withCredentials: true,
      })
      .then((response) => {
        setEventsList(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener el listado de eventos:", error);
      });
  }, []);

  const handleImageChange = (e) => {
    const files = e.target.files;
    const previewImages = [];

    for (let i = 0; i < files.length; i++) {
      previewImages.push(URL.createObjectURL(files[i]));
    }

    setSelectedImages(files);
    setImagePreview(previewImages);
  };

  const handleUploadImages = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("id_event", selectedEvent);

    for (let i = 0; i < selectedImages.length; i++) {
      formData.append("photos", selectedImages[i]);
    }

    const route = `${apiUrl}addPhotosEvent`;

    axios
      .post(route, formData, {
        withCredentials: true,
      })
      .then((response) => {
        setIsLoading(false);

        if (response.status === 200) {
          swalAlertSuccess(
            "IMAGENES CARGADAS",
            `¡Las imágenes fueron añadidas al evento con éxito!`,
            () => {
              navigate("/administrator_naiken");
            }
          );
        } else {
          swalAlertDanger("ERROR AL CARGAR", "Intentelo más tarde", () => {});
        }
      })
      .catch((error) => {
        // Ocultar el spinner
        setIsLoading(false);

        console.error("Error al enviar los datos:", error);
        swalAlertDanger(
          "ERROR AL CARGAR",
          "Intentelo con una carga de archivos menor",
          () => {}
        );
      });
  };

  return (
    <div className="container-cargar-fotos container">
      <div className="form-container table-container">
        <form className="carry-box">
          <b className="mb-3">
            Aquí podrá añadir imágenes a un evento existente
          </b>
          <div className="form-group user-table">
            <div className="name-evento mt-3 mb-4">
              <span>SELECCIONAR EVENTO</span>
            </div>
            <select
              id="eventSelect"
              className="form-control mt-3"
              value={selectedEvent}
              required
              onChange={(e) => setSelectedEvent(e.target.value)}
            >
              <option value="">Selecciona un evento</option>
              {eventsList.map((event) => (
                <option key={event.id_event} value={event.id_event}>
                  {event.name_event}
                </option>
              ))}
            </select>
            <div className="form-group mt-5">
              <div className="name-evento mb-4">
                <span>SELECCIONAR FOTOS</span>
              </div>
              <input
                id="imageInput"
                type="file"
                className="form-control"
                multiple
                required
                onChange={handleImageChange}
              />
            </div>
            <button
              className="btn btn-primary mt-3 mb-3"
              onClick={handleUploadImages}
              disabled={isLoading} // Deshabilitar el botón mientras se carga
            >
              {isLoading ? "Cargando..." : "Añadir imágenes"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormAddPhotos;
