import React, { useContext, useState } from "react";
import "./Forgot.css";
import axios from "axios";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertSuccess,
} from "../common/utils";
import { useNavigate } from "react-router-dom";
import { EmailForgotPass } from "../eventos/EventContext";

function ForgotPasswordEmailInput() {
  const [email, setEmail] = useState("");

  const { emailValue, setEmailValue } = useContext(EmailForgotPass);

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}user_forgot_password`;

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const response = await axios.post(
        route,
        { email: email },
        {
          "Content-Type": "application/json",
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        swalAlertSuccess(
          "CÓDIGO DE VERIFICACIÓN ENVIADO",
          "Verifica tu casilla de correo electrónico",
          () => {
            setEmailValue(email);
            navigate("/verification-code-forgot-password");
          }
        );
      } else {
        swalAlertInfo(
          "No se encontró la dirección de correo electrónico",
          "",
          () => {}
        );
      }
    } catch (error) {
      swalAlertDanger("OCURRIÓ UN ERROR EN EL SERVIDOR", "", () => {
        navigate("/");
      });
    }
  };

  return (
    <div className="container container-password noselect">
      <div className="row">
        <div className="col-6 mt-4">
          <h1>Recuperar contraseña</h1>
        </div>
        <div className="col-6">
          <form onSubmit={handleSubmit}>
            <input
              className="recuperar-contraseña-input"
              type="email"
              placeholder="Ingrese su correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className="submit-button-forgot">
              Enviar Código
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordEmailInput;
