import React, { useState, useEffect, useCallback } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.css";
import "./SliderStyles.css";

const Slider = ({ filesCarrousel }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide
      ? filesCarrousel.length - 1
      : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = useCallback(() => {
    const isLastSlide = currentIndex === filesCarrousel.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, filesCarrousel.length]);

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex, nextSlide]);

  return (
    <div className="slider-container">
      <div className="slider">
        <div className="slider-content">
          <button className="btn prev-btn" onClick={prevSlide}>
            <BsChevronCompactLeft size={40} />
          </button>

          {filesCarrousel.length > 0 ? (
            <img
              src={`${apiUrl}carrousel/${filesCarrousel[currentIndex].path_logo}`}
              alt="slide"
              className="slide"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
            />
          ) : (
            <div>CARGANDO...</div>
          )}

          <button className="btn next-btn" onClick={nextSlide}>
            <BsChevronCompactRight size={40} />
          </button>
        </div>

        <div className="slide-buttons">
          {filesCarrousel.map((slide, index) => (
            <div
              key={index}
              className={`dot ${currentIndex === index ? "active" : ""}`}
              onClick={() => goToSlide(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
