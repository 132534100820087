import React, { useState } from "react";
import "./PhotoGallery.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import NavbarUser from "../user/NavbarUser";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";

const PhotoGallery = ({ photos }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  return (
    <Container className="galery-container noselect">
      <NavbarUser />
      <Row>
        <div className="photo-gallery-container">
          <div className="gallery">
            {photos.length === 0
              ? ""
              : photos.map((photo, index) => (
                  <figure
                    key={index}
                    className="photo-item"
                    onClick={() => openLightbox(index)}
                  >
                    <Image
                      src={photo.photo_name}
                      alt={`Foto`}
                      className="photo-image"
                    />
                    <figcaption className="photo-caption">
                      Código: {photo.id_photo}
                    </figcaption>
                  </figure>
                ))}
          </div>
        </div>
      </Row>
      {lightboxOpen && (
        <Lightbox
          mainSrc={photos[currentImageIndex].photo_name}
          // Puedes agregar más props según la documentación de react-image-lightbox
          onCloseRequest={() => setLightboxOpen(false)}
        />
      )}
    </Container>
  );
};

export default PhotoGallery;
