import Swal from "sweetalert2";
import { toast } from "react-toastify";

//alerts pop-up
export const swalAlertSuccess = (title, text, onConfirm) => {
  Swal.fire({
    title: title,
    text: text,
    icon: "success",
    confirmButtonText: "Aceptar",
  }).then(() => {
    onConfirm();
  });
};

export const swalAlertDanger = (title, text, onConfirm) => {
  Swal.fire({
    title: title,
    text: text,
    icon: "error",
    confirmButtonText: "Aceptar",
  }).then(() => {
    onConfirm();
  });
};

export const swalAlertInfo = (title, text, onConfirm) => {
  Swal.fire({
    title: title,
    text: text,
    icon: "info",
    confirmButtonText: "Aceptar",
  }).then(() => {
    onConfirm();
  });
};
export const swalAlertInfoOrange = (title, message, onConfirm) => {
  Swal.fire({
    title: title,
    message: message,
    icon: "info",
    iconColor: "orange",
    confirmButtonText: "Aceptar",
  }).then(() => {
    onConfirm();
  });
};

export function swalConfirmationAlert(title, message, onConfirm) {
  Swal.fire({
    title: title,
    html: message,
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Confirmar",
    cancelButtonText: "Cancelar",
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    }
  });
}

//swal alert danger personalizada
export const swalAlertDangerWithCallback = (title, text, onConfirm) => {
  Swal.fire({
    title: title,
    text: text,
    icon: "error",
    confirmButtonText: "Aceptar",
  }).then(() => {
    onConfirm();
  });
};

//alertas toast
export const toastWarn = (message) => {
  toast.warn(message, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export const toastSuccess = (message) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};
export const toastError = (message) => {
  toast.error(message, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export const toastInfo = (message) => {
  toast.info(message, {
    position: "bottom-left",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};
