import React from "react";
import IndexAdmin from "../components/admin/IndexAdmin";
import DeleteEvent from "../components/admin/DeleteEvent";
import { validateRole } from "../controllers/controller_login";
import { useSelector } from "react-redux";

const AdminDeleteEvent = () => {
  const dataUser = useSelector((state) => state.user.dataUser)
  const allowedRoles = [0];
  if (!validateRole(dataUser.user_rol, allowedRoles)) {
    return null;
  } else {
    return (
      <>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
          <div>
            <IndexAdmin />
          </div>
          <div>
            <DeleteEvent />
          </div>
        </div>
      </>
    );
  }
};
export default AdminDeleteEvent;
