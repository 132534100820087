import React from "react";
import "./Aviso.css";
import { Link } from "react-router-dom";

function Aviso() {
  const linkStyle = {
    color: "white",
  };
  return (
    <div class="container mt-5">
      <div class="row">
        <div class="col">
          <div className="solicitud-fotografia solicitud-fotografia-link">
            {" "}
            <Link style={linkStyle} to="/login">
              Iniciar sesión{" "}
            </Link>{" "}
          </div>
        </div>
        <div class="col order-5">
          {" "}
          <div className="solicitud-fotografia solicitud-fotografia-link">
            {" "}
            <Link style={linkStyle} to="/help">
              Como solicitar fotografías{" "}
            </Link>{" "}
          </div>{" "}
        </div>
        <div class="col order-1">
          <div className="solicitud-fotografia solicitud-fotografia-link">
            {" "}
            <Link style={linkStyle} to="/register">
              Crear cuenta{" "}
            </Link>{" "}
          </div>
        </div>
      </div>
    </div>
    // <div className="container-aviso noselect">
    //   <div className="container">
    //     <div className="row">
    //       <div className="solicitud-fotografia col-4 ms-5 text-center">
    //         <div className="p-3">
    //           <h3>
    //             <div className="solicitud-fotografia-link">
    //               <Link style={linkStyle} to="/login">
    //                 Iniciar sesión
    //               </Link>{" "}
    //             </div>
    //           </h3>
    //         </div>
    //       </div>
    //       <div className="solicitud-fotografia col-4 ms-5 text-center">
    //         <div className="p-3">
    //           <h3>
    //             <div className="solicitud-fotografia-link">
    //               <Link style={linkStyle} to="/register">
    //                 Crear cuenta
    //               </Link>{" "}
    //             </div>
    //           </h3>
    //         </div>
    //       </div>
    //       <div className="solicitud-fotografia col-lg-9 col-md-9 col-sm-9 ms-4 p-4 text-center">
    //         <h5>
    //           <div className="solicitud-fotografia-link">
    //             <Link style={linkStyle} to="/help">
    //               COMO SOLICITAR FOTOGRAFÍAS{" "}
    //             </Link>{" "}
    //           </div>
    //         </h5>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Aviso;
