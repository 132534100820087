import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { ProgressBar } from "react-bootstrap";

export const EventContext = createContext();
export const UserContext = React.createContext();
export const UpdateDataUserContext = React.createContext(null);
export const EmailForgotPass = React.createContext();


export const EventProvider = ({ children }) => {
  const [eventName, setEventName] = useState("");
  const [dataUser, setDataUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [emailValue, setEmailValue] = useState(""); // Agrega el estado del email


  // const apiUrl = process.env.REACT_APP_API_URL;
  // const route = `${apiUrl}getDataUser`

  // useEffect(() => {
  //   Promise.all([axios.get(route, {
  //     withCredentials: true,
  //   })])
  //     .then((response) => {
  //       setDataUser(response[0].data);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => console.error(error));
  // }, []);

  const updateDataUser = (userData) => {
    setDataUser(userData);
  };

  // if (isLoading) {
  //   return (
  //     <>
  //       <div className="container bg-light p-4 mt-5 rounded">
  //         <div className="">Cargando...</div>
  //         <ProgressBar animated now={100} />
  //       </div>
  //     </>
  //   );
  // }

  return (
    <EventContext.Provider value={{ eventName, setEventName }}>
      <UserContext.Provider value={dataUser}>
        <UpdateDataUserContext.Provider value={updateDataUser}>
          <EmailForgotPass.Provider value={{ emailValue, setEmailValue }}>
            {children}
          </EmailForgotPass.Provider>
        </UpdateDataUserContext.Provider>
      </UserContext.Provider>
    </EventContext.Provider>
  );
};

export default EventContext;
