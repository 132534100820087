import React from "react";
import "../help/Aviso.css";
import { Link } from "react-router-dom";


function AvisoUser () {
    const linkStyle = {
        color: "white",
    };
    return(
        <div className="container-aviso col-md-12 noselect">
            <div className="container">
                <div className="row">
                    <div className="solicitud-fotografia">
                        <div className="p-3">
                            <h3>
                                <div className="solicitud-fotografia-link">
                                    <Link style={linkStyle} to="/help-user">
                                    ¿COMO SOLICITAR FOTOGRAFÍAS?{" "}
                                    </Link>{" "}    
                                </div>
                            </h3>
                        </div>
                    </div>

                </div>  

            </div>

        </div>
    )
}

export default AvisoUser;
