export const SET_DATA_USER = "SET_DATA_USER";
export const RESET_DATA_USER = "RESET_DATA_USER";


export const setUserData = (userData) => ({
  type: SET_DATA_USER,
  payload: userData,
});

export const resetUserState = () => ({
  type: RESET_DATA_USER,
});
