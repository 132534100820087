import NavbarUser from "../components/user/NavbarUser"
import FooterUser from "../components/footer/Footer"
import HelpUser from "../components/aviso-user/help-user"


const HelpUserPage = () => {
    return(
        <>
        <NavbarUser/>
        <HelpUser/>
        <FooterUser/>
        </>
    )
}

export default HelpUserPage