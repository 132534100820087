import React from "react";
import Evento2 from "../components/user2/Evento2.jsx";
import FooterUser from "../components/footer/FooterUser";

function EventUser() {
  return (
    <>
      <Evento2 />
      <FooterUser />
    </>
  );
}

export default EventUser;
