import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const EditPhotoModal = ({ show, handleClose, handleEditName, photoName }) => {
  const [newName, setNewName] = useState(photoName);

  const handleSave = () => {
    handleEditName(newName);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-danger">
          Editar nombre de la foto
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group">
            <label htmlFor="newName" className="text-success">
              Inserte nuevo nombre
            </label>
            <input
              type="text"
              className="form-control"
              id="newName"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
        </form>
        <div className="text-info mt-5">
          Nota: colocar la extensión con el nombre del archivo (.png , .jpg)
          Ejemplo: "CLA001 32.png"
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Guardar Cambios
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPhotoModal;
