import "./NavbarStyles.css";
import { Component } from "react";
import { MenuItems } from "./MenuItems";
import { Link } from "react-router-dom";

import Logo from "../../assets/img/logo2.jpg";

class Navbar extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <nav className="NavbarItems noselect">
        <div className="logo-container">
          <div className="logo-background"></div>
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="" className="image-logo"></img>
            </Link>
          </div>
        </div>
        <div className="menu-icons" onClick={this.handleClick}>
          <i
            className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
          ></i>
        </div>
        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          {MenuItems.map((item, index) => {
            return (
              <li className="mt-3" key={index}>
                <Link className={item.cName} to={item.url}>
                  <i className={item.icon}></i>
                  {item.title}
                </Link>
              </li>
            );
          })}

          <Link to="/login">
            <span className="button-sesion">
              <button>
                <span>INICIAR SESION</span>
              </button>
            </span>
          </Link>
        </ul>
      </nav>
    );
  }
}
export default Navbar;
