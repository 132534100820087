import Evento from "../components/eventos/Evento.jsx";
import Slider from "../components/carousel/Slider.jsx";
import Navbar from "../components/common/Navbar.jsx";
import Footer from "../components/footer/Footer.jsx";
import Aviso from "../components/help/Aviso.jsx";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { swalAlertDanger } from "../components/common/utils.js";

const Inicio = () => {
  const [filesCarrousel, setFilesCarrousel] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}admin_get_path_files_carrousel`;

  useEffect(() => {
    axios
      .get(route)
      .then((res) => {
        if (res.status === 200) {
          setFilesCarrousel(res.data.images);
        } else {
          swalAlertDanger(
            "ERROR AL CARGAR",
            "El servidor no está disponible",
            () => {}
          );
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "ERROR AL CARGAR",
          "El servidor no está disponible",
          () => {}
        );
      });
  }, [route]);

  return (
    <>
      <Navbar />
      <Slider filesCarrousel={filesCarrousel} />
      <Aviso />
      <Evento />
      <Footer />
    </>
  );
};

export default Inicio;
