import React, { useContext } from "react";
import { UserContext } from "../eventos/EventContext";
import { set, useForm } from "react-hook-form";
import "./LoginForm.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { verifyRoutes } from "../../controllers/controller_login";
import { swalAlertDanger } from "./utils";
import { UpdateDataUserContext } from "../eventos/EventContext";

import { setUserData } from "../redux/userActions";
import { useDispatch } from "react-redux";


const LoginForm = () => {
  const navigate = useNavigate();

  //const updateDataUser = useContext(UpdateDataUserContext);
  const dispatch = useDispatch();

  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}login`;
  const linkStyle = {
    color: "white",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      // const response = await axios.post("/login", data);
      const response = await axios.post(route, data, {
        "Content-Type": "application/json",
        withCredentials: true,
      });
      if (response.data.success) {
        // updateDataUser(response.data.user);
        const user = response.data.user
        dispatch(setUserData(user))
        const route = await verifyRoutes(response.data.user);
        navigate(route);
      } else {
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        swalAlertDanger("DATOS INCORRECTOS", "Vuelva a intentar.", ()=>{});
      }
    }
  };

  return (
    <div className="section noselect">
      <div className="container background-form-login">      
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="cover">
            <h2 className="mt-4">INICIAR SESION</h2>
            <input
              className="mt-5"
              type="text"
              name="username"
              id="username"
              placeholder="Email"
              autoFocus
              {...register("username", {
                required: true,
                message: "Campo obligatorio",
              })}
            />
            {errors.username && (
              <p className="text-danger">{errors.username.message}</p>
            )}
            <label htmlFor="username"></label>

            <div className="input">
              <input
                className="mt-3"
                type="password"
                name="password"
                id="password"
                placeholder="Contraseña"
                {...register("password", {
                  required: true,
                  message: "Campo obligatorio",
                })}
              />
              {errors.password && (
                <p className="text-danger">{errors.password.message}</p>
              )}
              <label htmlFor="password"></label>
            </div>
            <div className="text-center mt-5">
              <button type="submit" className="login-btn">
                INGRESAR
              </button>
              <div className="new-user mt-5">
                <Link style={linkStyle} to="/register">
                  ¿Eres nuevo? Crea una cuenta aquí
                </Link>
                <br></br>
                <Link style={linkStyle} to="/forgot-password">
                  ¿Olvidaste tu contraseña?
                </Link>
              </div>
            </div>
          </div>
          <div className="text-center mb-2"></div>
        </form>
        
      </div>
    </div>
  );
};
export default LoginForm;
