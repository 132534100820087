import React, { useEffect, useState } from "react";
import IndexAdmin from "../components/admin/IndexAdmin";
import { validateRole } from "../controllers/controller_login";
import { useSelector } from "react-redux";
import axios from "axios";
import { swalAlertDanger } from "../components/common/utils";
import { useNavigate } from "react-router-dom";

const AdminEventImagesEdit = () => {
  const dataUser = useSelector((state) => state.user.dataUser);
  const [eventList, setEventList] = useState([]);

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}getEventsList`;

  useEffect(() => {
    const fetchEventList = async () => {
      try {
        const response = await axios.get(route);
        setEventList(response.data);
      } catch (error) {
        swalAlertDanger(
          "Error en el servidor",
          "Intentelo de nuevo más tarde",
          () => {}
        );
        swalAlertDanger("ERROR AL OBTENER LOS EVENTOS", "", () => {});
      }
    };
    fetchEventList();
  }, [route]);

  const allowedRoles = [0];
  if (!validateRole(dataUser.user_rol, allowedRoles)) {
    return null;
  } else {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
        <div>
          <IndexAdmin />
        </div>

        <div className="row justify-content-center mt-5">
          {eventList.map((event) => (
            <div key={event.id_event} className="col-md-3 mb-5">
              <div className="card event-card">
                <h5 className="card-title">{event.name_event}</h5>
                <img
                  src={`${apiUrl}${event.name_event}/${event.path_logo}`}
                  alt="Event Logo"
                  className="event-logo"
                />
                <div className="card-body">
                  <p className="card-text">{event.date_event}</p>
                  <button
                    onClick={() =>
                      navigate(
                        `/photo_gallery_edit/${event.id_event}/${event.name_event}`
                      )
                    }
                    className="btn btn-success buttom-search btn-buscar-fotos"
                  >
                    EDITAR FOTOS
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default AdminEventImagesEdit;
