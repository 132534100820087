import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { EventContext } from "./EventContext";
import PhotoGallery from "../user/PhotoGalery";
import "./EventPhotosStyles.css";
import { Container, Row, Col } from "react-bootstrap";
import Prices from "../prices/prices";
import { useParams } from "react-router-dom";

const EventPhotos = () => {
  const { id_event, name_event } = useParams();

  const [eventPhotos, setEventPhotos] = useState([]);
  const [searchRunner, setSearchRunner] = useState("");
  const [visiblePhotos, setVisiblePhotos] = useState(20);
  const [loadingMore, setLoadingMore] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}event-photos/${name_event}/${searchRunner}`;

  const handleSearchRunner = async () => {
    try {
      const response = await axios.get(route, {
        withCredentials: true,
      });

      const imagesWithIds = response.data.images.map((image) => ({
        photo_name: `${apiUrl}${name_event}/${image.photo_name.trim()}`,
        id_photo: image.id_photo,
      }));
      setEventPhotos(imagesWithIds);
    } catch (error) {
      console.error("Error fetching event photos:", error);
    }
  };

  const filteredPhotos = eventPhotos.filter((photo) => {
    const match = photo.photo_name.match(/\b\d+\b/g);
    const corredorNumeros = match ? match.map(Number) : [];
    return corredorNumeros.some(
      (numero) => numero === parseInt(searchRunner, 10)
    );
  });

  return (
    <Container className="container-photos noselect">
      <h3>GALERIA DE FOTOS</h3>
      <h3 className="mt-5">{name_event}</h3>
      <div className="col-12 mt-5">
        <div className="d-grid mt-4">
          <div className="col-md-12">
            <Prices eventName={name_event} />
          </div>
          <div className="col-md-12 d-grid text-center justify-content-center">
            <span>¡Busca tu N° de corredor!</span>
            <input
              type="number"
              className="text-search-bar col-12 mt-5"
              placeholder="Buscar corredor..."
              value={searchRunner}
              onChange={(e) => {
                setSearchRunner(e.target.value);
              }}
            />
            <button
              className="btn btn-success col-12 mt-5"
              onClick={() => {
                handleSearchRunner();
              }}
            >
              BUSCAR FOTOS
            </button>
          </div>
        </div>
      </div>
      <Row>
        <Col className="name-event"></Col>
        <Col className="search-bar"></Col>
      </Row>
      <Row>
        <Col>
          <div className="photos-gallery">
            <Row>
              <PhotoGallery photos={filteredPhotos.slice(0, visiblePhotos)} />
            </Row>
            {visiblePhotos < filteredPhotos.length && (
              <div className="load-more">
                {loadingMore ? (
                  <p>Cargando...</p>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setLoadingMore(true);
                      setTimeout(() => {
                        setVisiblePhotos(visiblePhotos + 20);
                        setLoadingMore(false);
                      }, 1000);
                    }}
                  >
                    Cargar más
                  </button>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default EventPhotos;
