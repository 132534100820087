export const MenuItems = [
  {
    title: "INICIO",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "EVENTOS",
    url: "/eventos",
    cName: "nav-links",
  },
  {
    title: "Iniciar Sesion",
    url: "/login",
    cName: "nav-links-mobile",
  },
];
