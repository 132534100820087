import React from "react";
import IndexAdmin from "../components/admin/IndexAdmin";
import ChargeEvent from "../components/admin/scenes/cargar_evento/CargaEvento";
//import { UserContext } from "../components/eventos/EventContext";
import { validateRole } from "../controllers/controller_login";

import { useSelector } from "react-redux";

const AdminChargeEvent = () => {
  const dataUser = useSelector((state) => state.user.dataUser)
  const allowedRoles = [0];
  if (!validateRole(dataUser.user_rol, allowedRoles)) {
    return null;
  } else {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
        <div>
          <IndexAdmin />
        </div>
        <div>
          <ChargeEvent />
        </div>
      </div>
    );
  }
};

export default AdminChargeEvent;
