import React, { useEffect, useState } from "react";
import Slider from "../components/carousel/Slider";
import Evento from "../components/user2/Evento2.jsx";
import FooterUser from "../components/footer/FooterUser";
import HelpUser from "../components/aviso-user/aviso-user";
import axios from "axios";
import { swalAlertDanger } from "../components/common/utils.js";

const UserPage = () => {
  const [filesCarrousel, setFilesCarrousel] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}admin_get_path_files_carrousel`;

  useEffect(() => {
    axios
      .get(route)
      .then((res) => {
        if (res.status === 200) {
          setFilesCarrousel(res.data.images);
        } else {
          swalAlertDanger(
            "ERROR AL CARGAR",
            "El servidor no está disponible",
            () => {}
          );
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "ERROR AL CARGAR",
          "El servidor no está disponible",
          () => {}
        );
      });
  }, [route]);

  return (
    <>
      <Slider filesCarrousel={filesCarrousel} />
      <HelpUser />
      <Evento />
      <FooterUser />
    </>
  );
};

export default UserPage;
