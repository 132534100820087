import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import "../user2/EventoUser.css";
import NavbarAdmin from "./NavbarAdmin";
import { swalAlertDanger } from "../common/utils";

const EventList = () => {
  const [eventList, setEventList] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}getEventsList`;
  const navigate = useNavigate();

  useEffect(() => {
    fetchEventList();
  }, []);

  const fetchEventList = async () => {
    try {
      const response = await axios.get(route);
      setEventList(response.data);
    } catch (error) {
      swalAlertDanger(
        "Error en el servidor",
        "Intentelo de nuevo más tarde",
        () => {}
      );

      console.error("Error fetching event list:", error);
    }
  };

  return (
    <div className="container-event">
      <NavbarAdmin></NavbarAdmin>
      <div className="events-container">
        <div className="text-event">
          <h2>NUESTROS ÚLTIMOS EVENTOS</h2>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        {eventList.map((event) => (
          <div key={event.id_event} className="col-md-3 mb-5">
            <div className="card event-card">
              <h5 className="card-title">{event.name_event}</h5>
              <img
                src={`${apiUrl}${event.name_event}/${event.path_logo}`}
                alt="Event Logo"
                className="event-logo"
              />
              <div className="card-body">
                <p className="card-text">{event.date_event}</p>
                <button
                  onClick={() =>
                    // handleSearchPhotos(event.id_event, event.name_event)
                    navigate(
                      `/photo-gallery-user/${event.id_event}/${event.name_event}`
                    )
                  }
                  className="btn btn-success buttom-search btn-buscar-fotos"
                >
                  BUSCAR FOTOS
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventList;
