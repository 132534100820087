import LoginForm from "../components/common/LoginForm";
import Navbar from "../components/common/Navbar"

function Login() {
  return (
    <>
      <Navbar/>
      <LoginForm />
    </>
  );
}

export default Login;
