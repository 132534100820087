import React from "react";
import IndexAdmin from "../components/admin/IndexAdmin";
import UserAdministrator from "../components/admin/UserAdministrator";
import { validateRole } from "../controllers/controller_login";
import { useSelector } from "react-redux";

const AdminConsultUsers = () => {
  const dataUser = useSelector((state) => state.user.dataUser);
  const allowedRoles = [0];
  if (!validateRole(dataUser.user_rol, allowedRoles)) {
    return <div>NO DISPONIBLE</div>;
  } else {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
        <div>
          <IndexAdmin />
        </div>
        <div>
          <UserAdministrator />
        </div>
      </div>
    );
  }
};

export default AdminConsultUsers;
