import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTable, useFilters } from "react-table";
import {
  swalAlertSuccess,
  swalAlertDanger,
  swalAlertInfo,
} from "../common/utils";
import { useNavigate } from "react-router-dom";

const AdminPrices = () => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}getEventPrices`;
  const route2 = `${apiUrl}setEventPrices`;

  const navigate = useNavigate();

  useEffect(() => {
    const getPrices = async () => {
      try {
        // const response = await axios.get("/getEventPrices");
        const response = await axios.get(route, {
          withCredentials: true,
        });
        setPrices(response.data);
        setLoading(false);
      } catch (error) {
        swalAlertInfo(
          "SESION EXPIRADA",
          "Por favor, vuelva a iniciar sesión",
          () => {
            navigate("/login");
          }
        );
      }
    };

    getPrices();
  }, []);

  const handleEditPrice = async (id_price, showEditButton) => {
    const newPriceValue = parseFloat(prompt("Ingresar precio nuevo:"));

    if (showEditButton && !isNaN(newPriceValue)) {
      try {
        const response = await axios.post(
          route2,
          {
            id_price: id_price,
            price_value: newPriceValue,
          },
          {
            "Content-Type": "application/json",
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          setPrices((prevPrices) =>
            prevPrices.map((price) =>
              price.id_price === id_price
                ? { ...price, price_value: newPriceValue }
                : price
            )
          );
          swalAlertSuccess("PRECIO ACTUALIZADO", "", () =>
            navigate("/administrator_naiken")
          );
        } else {
          swalAlertDanger(
            "ERROR AL ACTUALIZAR",
            "Intentelo nuevamente más tarde",
            () => {
              navigate("/administrator_naiken");
            }
          );
        }
      } catch (error) {
        console.error("Error updating price:", error);
        swalAlertDanger(
          "ERROR AL ACTUALIZAR",
          "Intentelo nuevamente más tarde",
          () => {
            navigate("/administrator_naiken");
          }
        );
      }
    }
  };

  const data = React.useMemo(() => prices, [prices]);

  const columns = React.useMemo(
    () => [
      {
        Header: "DESCRIPCION",
        accessor: "price_description",
      },
      {
        Header: "PRECIO",
        accessor: "price_value",
      },
      {
        Header: "EDITAR",
        accessor: "id_price",
        Cell: ({ value }) => (
          <button onClick={() => handleEditPrice(value, true)}>Editar</button>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFilters
    );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-list-container">
      <h2>LISTADO DE PRECIOS</h2>
      <div className="table-container">
        <table {...getTableProps()} className="user-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className="table-header">
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminPrices;
