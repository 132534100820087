import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTable, useFilters } from "react-table";
import "./UserList.css"; // Importa los estilos personalizados

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}getUsersList`


  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axios.get(route,{
          withCredentials: true,
        });
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    getUsers();
  }, []);

  const data = React.useMemo(() => users, [users]);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID USUARIO",
        accessor: "user_id",
      },
      {
        Header: "Nombre",
        accessor: "name",
      },
      {
        Header: "Apellido",
        accessor: "lastname",
      },
      {
        Header: "Correo electrónico",
        accessor: "email",
      },
      {
        Header: "Contacto",
        accessor: "cellphone",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter, // Agregamos la función setFilter aquí
  } = useTable(
    {
      columns,
      data,
    },
    useFilters
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container user-list-container">
      <h2>CLIENTES NAIKEN</h2>
      <div className="search-bar">
        <span>¡BUSCA TU CLIENTE!</span>
        <input
          type="text"
          placeholder="Buscar cliente por id"
          onChange={(e) => {
            setFilter("user_id", e.target.value);
          }}
        />
      </div>
      <div className="table-container">
        <table {...getTableProps()} className="user-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className="table-header">
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserList;
