import React from "react";
import IndexAdmin from "../components/admin/IndexAdmin";
import FormAddPhotos from "../components/admin/FormAddPhotos";
// import { UserContext } from "../components/eventos/EventContext";
import { validateRole } from "../controllers/controller_login";

import { useSelector } from "react-redux";

const AdminAddPhotos = () => {
  const dataUser = useSelector((state) => state.user.dataUser)
  //const dataUser = useContext(UserContext);
  const allowedRoles = [0];
  if (!validateRole(dataUser.user_rol, allowedRoles)) {
    return null;
  } else {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
        <div>
          <IndexAdmin />
        </div>
        <div>
          <FormAddPhotos />
        </div>
      </div>
    );
  }
};
export default AdminAddPhotos;
