import React from "react";
import "../footer/HelpStyle.css";
import { Link } from "react-router-dom";

function Help() {
  const linkStyle = {
    color: "white",
  };
  return (
    <div className="container-help noselect">
      <h1 className="mt-4">¿Cómo solicitar fotografías?</h1>
      <p>1. Accede al evento deseado ingresando el N° de corredor.</p>
      <p>
        2. Dirígete al botón de WhatsApp indicando el N° de cliente, nombre del
        evento, N° de corredor y código de la foto, en el caso de no querer pack
        completo. Podrás visualizar tu N° de CLIENTE desde el perfil.
      </p>
      <p>
        3. Las opciones de pago serán acordadas por WhatsApp, según tus
        preferencias.
      </p>
      <p>
        4. Una vez abonadas las fotos te serán enviadas al mail por WeTransfer y
        WhatsApp, en el transcurso del día.
      </p>
    </div>
  );
}

export default Help;
