import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "./App";
import React from "react";

import { Provider } from "react-redux";
import userReducer from "./components/redux/userReducer.js";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = configureStore({
  reducer:{
    user: userReducer
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store)

//const rootElement = document.getElementById("root");
// const root = createRoot(rootElement);

root.render(
  <>
  <React.StrictMode>
  <HashRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </HashRouter>
  </React.StrictMode>
  </>
);
