import React, { useState } from "react";

const AddPhotosModal = ({ show, onHide, handleAddPhotos }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    setSelectedFiles(files);
  };

  const handleSubmit = async () => {
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("photos", selectedFiles[i]);
      }

      try {
        await handleAddPhotos(formData);
        setSelectedFiles([]);
        onHide();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div
      className={`modal ${show ? "show" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Añadir Fotos</h5>
            <button type="button" className="close" onClick={onHide}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form encType="multipart/form-data">
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="form-control"
              />
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onHide}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Cargar Fotos
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPhotosModal;
