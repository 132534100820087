import React from 'react'
import Evento from "../components/eventos/Evento.jsx"
import Footer from "../components/footer/Footer.jsx"


function EventosIndex() {
  return (
    <>
    <Evento/>
    <Footer/>
    </>
  )
}

export default EventosIndex