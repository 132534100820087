import React, { useContext } from "react";
import Navbar from "../components/common/Navbar";
import VerificationCodeInput from "../components/forgotpass/VerificationCodeInput";
import { EmailForgotPass } from "../components/eventos/EventContext";

const ForgotPassChargeCode = () => {
  const emailValue = useContext(EmailForgotPass);
  return (
    <div className="Container-ForgotPassword">
      <Navbar />
      <VerificationCodeInput emailValue={emailValue} />
    </div>
  );
};

export default ForgotPassChargeCode;
