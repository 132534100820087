import React, { useContext, useState, useEffect } from "react";
// import { UserContext } from "../eventos/EventContext";
import { UpdateDataUserContext } from "../eventos/EventContext";
import axios from "axios";
import NavbarUser from "./NavbarUser";
import { FaUserTie } from "react-icons/fa";
import { swalAlertDanger, swalAlertSuccess } from "../common/utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const Perfil = () => {
  const dataUser = useSelector((state) => state.user.dataUser)
  console.log(dataUser)
  const updateDataUser = useContext(UpdateDataUserContext)
  //const dataUser = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: dataUser.name,
    lastname: dataUser.lastname,
    email: dataUser.email,
    cellphone: dataUser.cellphone,
    user_id: dataUser.user_id,
  });
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}updateDataUser`;

  useEffect(() => {
    if (dataUser) {
      setIsLoading(false);
    }
  }, [dataUser]);

  if (isLoading) {
    return <p>Cargando...</p>;
  }

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    axios
      .post(
        route,
        {
          withCredentials: true,
        },
        formData
      )
      .then((response) => {
        if (response.status === 200) {
          updateDataUser(response.data.user)
          swalAlertSuccess("DATOS ACTUALIZADOS", "", () => {
            navigate("/perfil");
          });
        } else {
          swalAlertDanger(
            "OCURRIO UN ERROR AL ACTUALIZAR LOS DATOS",
            "",
            () => {
              navigate("/perfil");
            }
          );
        }
        setEditing(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <NavbarUser />
      <div
        className="container bg-light rounded p-3 noselect"
        style={{ marginTop: "30vh" }}
      >
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-sm-6 col-md-2 col-lg-2">
            <h3 style={{ color: "black" }}>MI PERFIL</h3>
          </div>
          <div className="col-12 col-sm-6 col-md-2 col-lg-2">
            <h3 style={{ color: "black" }}>
              N° DE CLIENTE: {dataUser.user_id}
            </h3>
          </div>
          <div className="col-12 col-sm-6 col-md-10 col-lg-10">
            <hr />
          </div>
        </div>

        <div className="row d-flex">
          <FaUserTie className="h1" style={{ color: "black" }} />
          <div className="text-center fw-bold h4" style={{ color: "black" }}>
            DATOS PERSONALES
          </div>

          <div className="col-12 col-sm-12 col-md-8 col-lg-6 mt-3">
            <b style={{ color: "black" }}>Nombre: </b>
            {editing ? (
              <input
                className="form-control input-perfil"
                type="text"
                name="name"
                defaultValue={formData.name}
                onChange={handleInputChange}
              />
            ) : (
              <b style={{ color: "black" }}>{dataUser.name}</b>
            )}
          </div>

          <div className="col-12 col-sm-12 col-md-8 col-lg-6 mt-3">
            <b style={{ color: "black" }}>Apellido: </b>
            {editing ? (
              <input
                type="text"
                className="form-control input-perfil"
                name="lastname"
                defaultValue={formData.lastname}
                onChange={handleInputChange}
              />
            ) : (
              <b style={{ color: "black" }}>{dataUser.lastname}</b>
            )}
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
            <b style={{ color: "black" }}>Correo: </b>
            {editing ? (
              <input
                className="form-control input-perfil"
                type="email"
                name="email"
                defaultValue={formData.email}
                onChange={handleInputChange}
              />
            ) : (
              <b style={{ color: "black" }}>{dataUser.email}</b>
            )}
          </div>

          <div className="col-12 col-sm-12 col-md-4 col-lg-6 mt-3">
            <b style={{ color: "black" }}>Teléfono: </b>
            {editing ? (
              <input
                className="form-control input-perfil"
                type="tel"
                name="cellphone"
                defaultValue={formData.cellphone}
                onChange={handleInputChange}
              />
            ) : (
              <b style={{ color: "black" }}>{dataUser.cellphone}</b>
            )}
          </div>
        </div>
        {editing && (
          <div className="row d-flex mt-5 px-3">
            <button
              onClick={handleSubmit}
              className="btn btn-primary button-perfil mt-2"
              style={{ backgroundColor: "black", color: "white" }}
            >
              Guardar
            </button>
          </div>
        )}
        {!editing && (
          <div className="row d-flex mt-5 px-3">
            <button
              onClick={() => setEditing(true)}
              className="btn btn-primary button-perfil mt-2"
              style={{ backgroundColor: "black", color: "white" }}
            >
              Editar
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Perfil;
