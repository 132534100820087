import React, { useState } from "react";
import "./Forgot.css";
import axios from "axios";
import { swalAlertInfo, swalAlertSuccess } from "../common/utils";
import { useNavigate } from "react-router-dom";

function VerificationCodeInput({ emailValue }) {
  const [code, setCode] = useState("");

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}user_verification_code`;

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const data = {
        email: emailValue.emailValue,
        code: code,
      };
      const response = await axios.post(route, data, {
        "Content-Type": "application/json",
        withCredentials: true,
      });
      if (response.status === 200) {
        swalAlertSuccess(
          "VERIFICACIÓN EXITOSA",
          "Modifique su contraseña por una nueva",
          () => {
            navigate("/change-forgot-password")
          }
        );
      } else {
        swalAlertInfo("VERIFICACIÓN INVALIDA", "Vuelva a intentarlo", () => {});
      }
    } catch (error) {
    }
  };

  return (
    <div className="container container-password noselect">
      <div className="row">
      <div className="col-6 mt-4">
      <h1>Verificar Código</h1>
      </div>
      <div className="col-6">
      <form onSubmit={handleSubmit}>
        <input
        className="recuperar-contraseña-input"
          type="text"
          placeholder="Ingrese el código de verificación"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <button type="submit" className="submit-button-forgot">
          Verificar
        </button>
      </form>
      </div>
      </div>
    </div>
  );
}

export default VerificationCodeInput;
