import Navbar from "../components/common/Navbar.jsx";
import Footer from "../components/footer/Footer.jsx";
import Help from "../components/footer/Help.jsx";


const HelpPage = () => {
    return (
      <>
        <Navbar />
        <Help/>
        <Footer/>
      </>
    );
  }
  
  export default HelpPage;