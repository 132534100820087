import "./index.css";
import "./styles.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/common/Navbar";
// USER
import Index from "./pages/Index";
import Login from "./pages/Login";
import Register from "./components/common/Register";
import EventList from "./pages/EventosIndex";
import EventGallery from "./components/eventos/EventPhotos";
import UserPage from "./pages/UserPage";
import Perfil from "./components/user/PerfilUser";
import EventListUser from "./pages/EventUser";
import HelpPage from "./pages/HelpPage";
import HelpUser from "./pages/HelpUser";
import EventGalleryUser from "./components/user2/EventPhotosUser";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPassChargeCode from "./pages/ForgotPassChargeCode";
import ForgotPassChangePass from "./pages/ForgotPassChangePass";
// ADMIN
import Admin from "./components/admin/IndexAdmin";
import AdminChargeEvent from "./pages/AdminChargeEvent";
import { EventProvider } from "./components/eventos/EventContext";
import AdminConsultUsers from "./pages/AdminConsultUsers";
import AdminChangePrice from "./pages/AdminChangePrice";
import AdminDeleteEvent from "./pages/AdminDeleteEvent";
import AdminPrev from "./pages/AdminPrev";
import AdminAddPhotos from "./pages/AdminAddPhotos";
import AdminImagesCarrousel from "./pages/AdminImagesCarrousel";
import AdminEventImagesEdit from "./pages/AdminEventImagesEdit";
import EventEditPhotos from "./components/admin/EventEditPhotos";

export default function App() {
  const location = useLocation();
  const showNavbar = location.pathname === "/eventos";

  return (
    <EventProvider>
      <div className="App">
        {showNavbar && <Navbar />}
        <Routes>
          {/* RUTAS USUARIO */}
          <Route path="/" element={<Index />} />
          <Route path="/user" element={<UserPage />} />
          <Route path="/eventos" element={<EventList />} />
          <Route
            path="/photo-gallery/:id_event/:name_event"
            element={<EventGallery />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/perfil" element={<Perfil />} />
          <Route path="/eventos-user" element={<EventListUser />} />
          <Route path="/help" element={<HelpPage />} />
          <Route
            path="/photo-gallery-user/:id_event/:name_event"
            element={<EventGalleryUser />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route
            path="/verification-code-forgot-password"
            element={<ForgotPassChargeCode />}
          ></Route>
          <Route
            path="/change-forgot-password"
            element={<ForgotPassChangePass />}
          ></Route>
          <Route path="/help-user" element={<HelpUser />}></Route>

          {/* RUTAS ADMINISTRADOR */}

          <Route path="/administrator_naiken" element={<Admin />} />
          <Route path="/cargar_evento" element={<AdminChargeEvent />} />
          <Route
            path="/naiken_admin_add_images_event"
            element={<AdminAddPhotos />}
          />
          <Route
            path="/naiken_admin_images_carrousel"
            element={<AdminImagesCarrousel />}
          />

          <Route
            path="/naiken_admin_images_event_edit"
            element={<AdminEventImagesEdit />}
          />

          <Route
            path="/photo_gallery_edit/:id_event/:name_event"
            element={<EventEditPhotos />}
          />

          <Route path="/admin_users_editor" element={<AdminConsultUsers />} />
          <Route
            path="/admin_users_price_edit"
            element={<AdminChangePrice />}
          />
          <Route path="/admin_prev" element={<AdminPrev />} />
          <Route
            path="/naiken_cba_delete_event_admin"
            element={<AdminDeleteEvent />}
          />
        </Routes>
      </div>
    </EventProvider>
  );
}
