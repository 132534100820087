import React from "react";
import { Link } from "react-router-dom";
import "./UserMenuStyles.css";

const UserMenu = () => {
  return (
    <div className="user-menu noselect">
      <Link to="/login" className="user-menu-item logout-link">
        CERRAR SESION
      </Link>
    </div>
  );
};

export default UserMenu;
