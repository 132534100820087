import React, { useState } from "react";
import "../user/PhotoGallery.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  swalAlertDanger,
  swalAlertInfo,
  swalAlertInfoOrange,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../common/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EditPhotoModal from "./EditPhotoModal";

const EventEditPhotoGallery = ({ photos }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingPhotoId, setEditingPhotoId] = useState(null);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  // Manejador para abrir el modal de edición
  const handleOpenEditModal = (id) => {
    setEditingPhotoId(id);
    setShowEditModal(true);
  };

  // Manejador para cerrar el modal de edición
  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  // Manejador para editar el nombre de la foto
  const handleEditPhotoName = (id, newName) => {
    const routeEdit = `${apiUrl}edit_photo_name`;
    axios
      .post(
        routeEdit,
        { id, newName },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          swalAlertSuccess(
            "CAMBIOS GUARDADOS",
            "Se modificó el nombre de la foto",
            () => {
              navigate("/naiken_admin_images_event_edit");
            }
          );
        } else {
          swalAlertInfo(
            "ERROR AL MODIFICAR EL NOMBRE",
            "Verifique que el nombre de la foto no exista actualmente",
            () => {
              navigate("/naiken_admin_images_event_edit");
            }
          );
        }
      })
      .catch((error) => {
        swalAlertInfo(
          "ERROR AL MODIFICAR EL NOMBRE",
          "Verifique que el nombre de la foto no exista actualmente",
          () => {
            navigate("/naiken_admin_images_event_edit");
          }
        );
      });
  };

  // Manejador para eliminar una foto
  const handleDeletePhoto = (id) => {
    const routeDelete = `${apiUrl}delete_image_event`;
    swalConfirmationAlert(
      "¿DESEA ELIMINAR LA FOTO SELECCIONADA?",
      "No podrá deshacer estos cambios",
      () => {
        axios
          .post(
            routeDelete,
            { id },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess("FOTO ELIMINADA", "", () => {
                navigate("/naiken_admin_images_event_edit");
              });
            } else {
              swalAlertInfoOrange(
                "ERROR AL ELIMINAR",
                "Intente nuevamente más tarde",
                () => {
                  navigate("/administrator_naiken");
                }
              );
            }
          })
          .catch((error) => {
            swalAlertDanger("ERROR EN EL SERVIDOR", "", () => {
              navigate("/administrator_naiken");
            });
          });
      }
    );
  };

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  return (
    <Container className="galery-container noselect">
      <Row>
        <div className="photo-gallery-container">
          <div className="gallery">
            {photos.length === 0
              ? ""
              : photos.map((photo, index) => (
                  <figure key={index} className="photo-item">
                    <Image
                      src={photo.photo_name}
                      alt={`Foto`}
                      className="photo-image"
                    />
                    <figcaption className="photo-caption">
                      Código: {photo.id_photo}
                    </figcaption>
                    <Button
                      variant="danger"
                      onClick={() => handleDeletePhoto(photo.id_photo)}
                      className="delete-button"
                    >
                      <DeleteIcon /> {/* Icono para eliminar */}
                    </Button>
                    <Button
                      variant="info" // Puedes ajustar el estilo según tus preferencias
                      onClick={() => handleOpenEditModal(photo.id_photo)}
                      className="edit-button ms-3"
                    >
                      <EditIcon /> {/* Icono para editar */}
                    </Button>
                  </figure>
                ))}
          </div>
        </div>
      </Row>
      {lightboxOpen && (
        <Lightbox
          mainSrc={photos[currentImageIndex].photo_name}
          onCloseRequest={() => setLightboxOpen(false)}
        />
      )}

      {/* Renderiza el modal de edición */}
      <EditPhotoModal
        show={showEditModal}
        handleClose={handleCloseEditModal}
        handleEditName={(newName) =>
          handleEditPhotoName(editingPhotoId, newName)
        }
        // photoName={/* Obtén el nombre de la foto que se está editando */}
      />
    </Container>
  );
};

export default EventEditPhotoGallery;
