import React, { useEffect, useState } from "react";
import IndexAdmin from "../components/admin/IndexAdmin";
import { validateRole } from "../controllers/controller_login";
import { useSelector } from "react-redux";
import CarrouselPhotos from "../components/admin/CarrouselPhotos";
import axios from "axios";
import {
  swalAlertDanger,
  swalAlertInfoOrange,
  swalAlertSuccess,
  swalConfirmationAlert,
} from "../components/common/utils";
import { useNavigate } from "react-router-dom";

const AdminImagesCarrousel = () => {
  const dataUser = useSelector((state) => state.user.dataUser);
  const navigate = useNavigate();
  const [filesCarrousel, setFilesCarrousel] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}admin_get_path_files_carrousel`;

  useEffect(() => {
    axios
      .get(route)
      .then((res) => {
        if (res.status === 200) {
          setFilesCarrousel(res.data.images);
        } else {
          swalAlertDanger(
            "ERROR AL CARGAR",
            "El servidor no está disponible",
            () => {}
          );
        }
      })
      .catch((error) => {
        swalAlertDanger(
          "ERROR AL CARGAR",
          "El servidor no está disponible",
          () => {}
        );
      });
  }, [route]);

  const handleDelete = (data) => {
    const routeDelete = `${apiUrl}admin_delete_photo_carrousel`;
    swalConfirmationAlert(
      "¿DESEA ELIMINAR ESTA FOTO?",
      "No podrá deshacer esta acción",
      () => {
        axios
          .post(routeDelete, { data })
          .then((res) => {
            if (res.status === 200) {
              swalAlertSuccess("FOTO ELIMINADA", "", () => {
                navigate("/administrator_naiken");
              });
            } else {
              swalAlertInfoOrange(
                "OCURRIO UN ERROR AL ELIMINAR",
                "Intentelo nuevamente más tarde",
                () => {
                  navigate("/administrator_naiken");
                }
              );
            }
          })
          .catch((error) => {
            console.log(error);
            swalAlertDanger(
              "Error al eliminar la imágen",
              "Intente nuevamente más tarde",
              () => {
                navigate("/administrator_naiken");
              }
            );
          });
      }
    );
  };

  const handleAddPhotos = async (data) => {
    const routeAddPhotos = `${apiUrl}admin_add_photos_carrousel`;
    try {
      const res = await axios.post(routeAddPhotos, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        swalAlertSuccess(`${res.data.msg}`, "", () => {
          navigate("/administrator_naiken");
        });
      } else {
        swalAlertDanger(
          "ERROR AL CARGAR LAS IMÁGENES",
          "INTENTELO NUEVAMENTE MAS TARDE",
          () => {
            navigate("/administrator_naiken");
          }
        );
      }
    } catch (error) {
      swalAlertDanger(
        "ERROR AL CARGAR LAS IMÁGENES",
        "INTENTELO NUEVAMENTE MAS TARDE",
        () => {
          navigate("/administrator_naiken");
        }
      );
    }
  };

  const allowedRoles = [0];
  if (!validateRole(dataUser.user_rol, allowedRoles)) {
    return null;
  } else {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
        <div>
          <IndexAdmin />
        </div>
        <div>
          <CarrouselPhotos
            filesCarrousel={filesCarrousel}
            handleDelete={handleDelete}
            handleAddPhotos={handleAddPhotos}
          />
        </div>
      </div>
    );
  }
};

export default AdminImagesCarrousel;
