import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../cargar_evento/CargaEvento.css";
import { swalAlertDanger, swalAlertSuccess } from "../../../common/utils";
import axios from "axios";

const FormComponent = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}getEventTypes`;

  const navigate = useNavigate();
  const [logo, setLogo] = useState(null);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [photos, setPhotos] = useState([]);

  const [eventTypes, setEventTypes] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(route, {
        withCredentials: true,
      })
      .then((response) => {
        setEventTypes(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener los tipos de evento:", error);
      });
  }, []);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setLogo(file);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handlePhotoChange = (event) => {
    const files = Array.from(event.target.files);
    setPhotos(files);
  };

  const handleEventTypeChange = (event) => {
    setSelectedEventType(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("logo", logo);
    formData.append("name", name);
    formData.append("date", date);
    formData.append("eventType", selectedEventType);
    photos.forEach((photo, index) => {
      formData.append(`photos`, photo);
    });
    const routeCreateEvent = `${apiUrl}createEvent`;
    axios
      .post(routeCreateEvent, formData, {
        withCredentials: true, // Agrega esta opción para enviar cookies
      })
      .then((response) => {
        setIsLoading(false); // Ocultar la animación de carga
        if (response.status === 200) {
          swalAlertSuccess(
            "EVENTO CARGADO",
            `El evento ${name} fue registrado con éxito!`,
            () => {
              navigate("/administrator_naiken");
            }
          );
        } else {
          swalAlertDanger(
            "ERROR AL CARGAR EL EVENTO",
            "Intentelo más tarde",
            () => {}
          );
        }
      })
      .catch((error) => {
        setIsLoading(false); // Ocultar la animación de carga
        console.error("Error al enviar los datos:", error);
        swalAlertDanger(
          "ERROR AL CARGAR EL EVENTO",
          "Intentelo más tarde",
          () => {}
        );
      });
  };

  return (
    <div className="container-cargar-fotos">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <form
            className="carry-box"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <h2 className="text-center">REGISTRAR EVENTO</h2>
            <div className="row mt-5">
              <div className="col-md-6">
                <div className="name-evento">
                  <span>Logo del evento</span>
                </div>
                <div className="form-control-input mb-3 mt-2">
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleLogoChange}
                    placeholder="Logo"
                    required
                  />
                </div>
                <div className="mb-3 mt-2">
                  <div className="name-evento">
                    <span>Fotos del evento</span>
                  </div>
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    name="photos"
                    onChange={handlePhotoChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="name-evento">
                  <span>Nombre del evento</span>
                </div>
                <div className="mb-3 mt-2">
                  <input
                    type="text"
                    className="form-control-text"
                    value={name}
                    onChange={handleNameChange}
                    placeholder="Nombre del evento"
                    required
                  />
                </div>
                <div className="mb-3 mt-2">
                  <div className="name-evento">
                    <span>Fecha del evento</span>
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    value={date}
                    onChange={handleDateChange}
                    placeholder="Fecha de publicación"
                    required
                  />
                </div>
              </div>
              <div className="mb-3 mt-2">
                <div className="name-evento">
                  <span>Tipo de evento</span>
                </div>
                <select
                  className="form-control"
                  value={selectedEventType}
                  onChange={handleEventTypeChange}
                  required
                >
                  <option value="">Seleccione un tipo de evento</option>
                  {eventTypes.map((eventType) => (
                    <option key={eventType.id} value={eventType.id}>
                      {eventType.type_description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {isLoading ? (
              <div className="loading-animation">
                <div className="loading-spinner"></div>
                Cargando evento, por favor espere...
              </div>
            ) : (
              <button type="submit" className="btn btn-primary">
                CARGAR EVENTO
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default FormComponent;
