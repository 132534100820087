import Evento from "../components/admin/Evento3";
// import { useContext } from "react";
// import { UserContext } from "../components/eventos/EventContext";
import { validateRole } from "../controllers/controller_login";

import { useSelector } from "react-redux";

const AdminPrev = () => {
  const dataUser = useSelector((state) => state.user.dataUser)
  // const dataUser = useContext(UserContext);
  const allowedRoles = [0];
  if (!validateRole(dataUser.user_rol, allowedRoles)) {
    return null;
  } else {
    return (
      <>
        <Evento />
      </>
    );
  }
};

export default AdminPrev;
