import React, { useEffect, useState } from "react";
import axios from "axios";
import "./prices.css";

const EventPrices = ({ eventName }) => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Realiza la solicitud GET al servidor y obtiene los datos
    const route = `${apiUrl}getEventPrices/${eventName}`;
    axios
      .get(route, {
        withCredentials: true,
      })
      .then((response) => {
        const eventData = response.data;
        if (Array.isArray(eventData)) {
          const extractedPrices = eventData.map((event) => ({
            price_description: event.price_description,
            price_value: event.price_value,
          }));

          setPrices(extractedPrices);
          setLoading(false);
        } else {
          // Manejar el caso en el que eventData no es un array, por ejemplo, mostrar un mensaje de error
          console.error("La respuesta del servidor no es un array:", eventData);
        }
      })
      .catch((error) => {
        console.error("Error al obtener los datos:", error);
      });
  }, [eventName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ul className="text-info noselect">
        {prices &&
          prices.map((price, index) => (
            <li key={index}>
              {price.price_description} : ${price.price_value} 
            </li>
          ))}
      </ul>
    </div>
  );
};

export default EventPrices;
