import React, { Component } from "react";
import { MenuItemsAdmin } from "./MenuItemsAdmin";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import UserMenu from "../user/UserMenu";
import Logo from "../../assets/img/logo2.jpg";
import "./NavbarAdmin.css";

class NavbarAdmin extends Component {
  state = {
    clicked: false,
    showMenu: false,
  };

  handleClick = () => {
    this.setState((prevState) => ({
      clicked: !prevState.clicked,
      showMenu: !prevState.showMenu,
    }));
  };

  handleMouseEnter = () => {
    this.setState({ showMenu: true });
  };

  handleMouseLeave = () => {
    this.setState({ showMenu: false });
  };

  render() {
    return (
      <nav className="NavbarItemsAdmin">
        <div className="menu-icons" onClick={this.handleClick}>
          <i
            className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
          ></i>
        </div>
        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          {MenuItemsAdmin.map((item, index) => {
            return (
              <li key={index}>
                <Link className={item.cName} to={item.url}>
                  {item.title}
                </Link>
              </li>
            );
          })}
          <li
            className="nav-item dropdown"
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="user-dropdown">
                <a className="fas fa-user" style={{ color: "white" }}></a>
              </Dropdown.Toggle>
              {this.state.showMenu && (
                <Dropdown.Menu
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: -90,
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  <UserMenu />
                </Dropdown.Menu>
              )}
            </Dropdown>
          </li>
        </ul>
      </nav>
    );
  }
}

export default NavbarAdmin;
