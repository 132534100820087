import React from "react";
import "./FooterUserStyle.css"
import LogoFooter from "../../assets/img/logo3.png"
import FacebookIcon from "../../assets/img/facebook.svg"
import InstagramIcon from "../../assets/img/instagram.svg"
import { Link } from "react-router-dom";


export const FooterUser = () => {
    return( 
        <div className="footer-user noselect">
            <div className="section-1">
                <div className="section-2">
                    <div className="logo-container1">
                    <Link to="/">
                        <img className="logo-footer-user" src={LogoFooter} alt= "Logo Naiken"></img>
                    </Link>
                    </div>
                    <span className="footer-social-icon">
                        <a href="https://www.facebook.com/profile.php?id=100035761005668&ref=nf&hc_ref=ARRUJATQcZTCu9JML4zI9PBJZaXV76HbXsmITMJG27jLf78NdRo9C23s6_D59PWhEno/">
                            <img src={FacebookIcon} alt="Facebook Naiken"></img>
                        </a>
                        <a href="https://www.instagram.com/naikenfotografia/">
                            <img src={InstagramIcon} alt="Instagram Naiken"></img>
                        </a>
  
                    </span>
                </div>
        <div className="section-3">
        
            <menu>
                <h4>Eventos</h4>
                <li>
                    <Link to="/eventos">Eventos</Link>
                    </li>
            </menu>

        </div>
        <div className="section-3">
            <menu>
                <h4>Ayuda</h4>
                <li><Link to="/help">¿Como solicitar Fotografías?</Link></li>
            </menu>
        </div>
        

        <div className="footer-bottom-user">
            <small>&copy; 2023 Derechos de autor. Todos los derechos reservados.</small>
        </div>
            </div>
        </div>
    )
}

export default FooterUser;