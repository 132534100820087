import React, { useState } from "react";
import AddPhotosModal from "./AddPhotosModal";

const CarrouselPhotos = ({ filesCarrousel, handleDelete, handleAddPhotos }) => {
  const [showModal, setShowModal] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="container mt-5">
      <h2>CARROUSEL DE FOTOS INICIO</h2>
      <table className="table table-striped container mt-4">
        <thead>
          <tr>
            <th>ID</th>
            <th>Foto</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          {filesCarrousel.map((photo) => (
            <tr key={photo.id_photo}>
              <td>{photo.id_photo}</td>
              <td>
                <img
                  src={`${apiUrl}carrousel/${photo.path_logo}`}
                  alt={`Photo ${photo.id_photo}`}
                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
              </td>
              <td>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => handleDelete(photo)}
                >
                  ELIMINAR
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="card card-body">
        <button
          className="btn btn-success col-12 mt-2"
          onClick={handleShowModal}
        >
          AÑADIR FOTOS
        </button>
      </div>
      <AddPhotosModal
        show={showModal}
        onHide={handleCloseModal}
        handleAddPhotos={handleAddPhotos}
      />
    </div>
  );
};

export default CarrouselPhotos;
