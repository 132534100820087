import React, { useContext } from "react";
import Navbar from "../components/common/Navbar";
import ChangePassword from "../components/forgotpass/ChangePassword";
import { EmailForgotPass } from "../components/eventos/EventContext";

const ForgotPassChargePass = () => {
  const emailValue = useContext(EmailForgotPass);
  return (
    <div className="Container-ForgotPassword">
      <Navbar />
      <ChangePassword emailValue={emailValue} />
    </div>
  );
};

export default ForgotPassChargePass;
