import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import UserMenu from "./UserMenu";
import Logo from "../../assets/img/logo2.jpg";
import Whatsapp from "../../assets/img/wsp.png";
import "./NavbarUser.css";
import { MenuItems } from "./MenuItemsUser";
// import { UserContext } from "../eventos/EventContext";

import { useSelector } from "react-redux";

const NavbarUser = () => {
  const dataUser = useSelector((state) => state.user.dataUser)
  //const dataUser = useContext(UserContext);

  const [clicked, setClicked] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
    setShowMenu(!showMenu);
  };

  const handleMouseEnter = () => {
    setShowMenu(true);
  };

  const handleMouseLeave = () => {
    setShowMenu(false);
  };

  return (
    <nav className="NavbarItemsUser noselect">
      <div className="logo-container-user">
        <div className="logo-background"></div>
        <div className="logo">
          <Link to="/user">
            <img src={Logo} alt="" className="image-logo"></img>
          </Link>
        </div>
      </div>
      <div className="logo-container2">
        <a
          href="#"
          className="btn-whatsapp"
          onClick={(e) => {
            e.preventDefault();
            const message = encodeURIComponent(
              `Hola, mi número de cliente es: ${dataUser.user_id}
              
              Quisiera comprar las fotos del corredor: 
              - (N° Corredor) perteneciente a : 
              - (Ingrese nombre del pack o código de foto en caso de querer individuales)
              ¡Gracias!`
            );
            window.open(
              `https://api.whatsapp.com/send?phone=3515648420&text=${message}`,
              "_blank"
            );
          }}
        >
          <img src={Whatsapp} alt="Contactar por WhatsApp" />
        </a>
      </div>
      <div className="menu-icons" onClick={handleClick}>
        <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
      <ul className={clicked ? "nav-menu active" : "nav-menu"}>
        {MenuItems.map((item, index) => {
          return (
            <li key={index}>
              <Link className={item.cName} to={item.url}>
                {item.title}
              </Link>
            </li>
          );
        })}
        <li
          className="nav-item dropdown"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="user-dropdown">
              <a className="fas fa-user" style={{ color: "white" }}></a>
            </Dropdown.Toggle>
            {showMenu && (
              <Dropdown.Menu
                style={{
                  position: "absolute",
                  top: "100%",
                  left: -90,
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <UserMenu />
              </Dropdown.Menu>
            )}
          </Dropdown>
        </li>
      </ul>
    </nav>
  );
};

export default NavbarUser;
