import React, { useState } from "react";
import "./RegisterStyles.css";
import { swalAlertDanger, swalAlertInfo, swalAlertSuccess } from "./utils";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import "react-bootstrap"

function RegisterForm() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [contrasena, setPassword] = useState("");
  const [cellphone, setCellphone] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;
  const route = `${apiUrl}register`

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newUser = {
      name: name,
      lastname: lastname,
      email: email,
      password: contrasena,
      cellphone: cellphone,
    };

    // Enviar el nuevo usuario al servidor
    try {
      const response = await fetch(route, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUser),
      });

      if (response.ok) {
        // Usuario registrado exitosamente
        swalAlertSuccess(
          "USUARIO REGISTRADO EXITOSAMENTE",
          "Ya puede iniciar sesión con las credenciales generadas"
        );
        navigate("/login");
        // Limpiar los campos del formulario después del envío
        setName("");
        setLastname("");
        setEmail("");
        setPassword("");
        setCellphone("");

        //Redireccionar a login
      } else {
        if (response.status === 401) {
          swalAlertInfo(
            "DATOS INCORRECTOS",
            "Verifique que los datos ingresados sean correctos"
          );
        }
      }
    } catch (error) {
      swalAlertDanger(
        "SERVIDOR NO DISPONIBLE",
        "Intentelo nuevamente más tarde"
      );
    }
  };

  return (
    <div className="container2 noselect">
      <Navbar />
      <h1 className="text-dark mb-5">Registrate</h1>
      <form onSubmit={handleSubmit}>
        <label className="text-dark" htmlFor="nombre">
          Nombre:
        </label>
        <input
          type="text"
          className="nombre-form"
          id="nombre"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <br />

        <label className="text-dark" htmlFor="apellido">
          Apellido:
        </label>
        <input
          type="text"
          className="apellido-form"
          id="apellido"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          required
        />

        <br />
        <label className="text-dark" htmlFor="mail">
          Mail:
        </label>
        <input
          type="email"
          className="email-form"
          id="mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <br />
        <label className="text-dark" htmlFor="contrasena">
          Contraseña:
        </label>
        <input
          type="password"
          className="password-form"
          id="contrasena"
          value={contrasena}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <caption className="text-info1 text-center">
          La contraseña deberá contener al menos 8 caracteres (1 mayúscula, 1 minuscula y 1
          digito)
        </caption>
        <br />

        <label className="text-dark" htmlFor="telefono">
          Celular:
        </label>
        <input
          type="tel"
          className="telefono-form"
          id="telefono"
          value={cellphone}
          onChange={(e) => setCellphone(e.target.value)}
          required
        />
        <caption className="text-info1 text-center">
          Coloca tu número celular con la característica y sin el 0. Ej: 351XXXXXXX
        </caption>

        <br />
        <button type="submit" className="btn mt-2">
          Registrarse
        </button>
      </form>
    </div>
  );
}

export default RegisterForm;
